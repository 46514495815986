<template>
  <div class="notification">
    <div class="notification__text">
      <span v-html="notification.text"></span>
    </div>
    <a class="notification__close" @click="removeNotification">
      <i class="material-icons pointer">icon_close</i>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    notification: Object
  },
  methods: {
    removeNotification() {
      this.$store.dispatch('notifications_delete', this.notification.guid);
    }
  },
  created() {
    if (!this.notification.withAction) {
      setTimeout(() => {
        this.removeNotification();
      }, 4000);
    }
  }
};
</script>

<style lang="scss">
.notification {
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 400px;
  background: $orange;
  border-radius: 20px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
  padding: $margin-base;
  padding-right: $margin-tera;
  margin: $margin-milli 0;
  position: relative;
  color: #202020;

  &__text {
    color: #202020;
    font-weight: normal;

    span {
      margin-right: $margin-milli;
      //color: #202020;
      color:$white;
      @include font-settings(milli, base);
    }
  }

  &__close {
    position: absolute;
    right: $margin-base;
    color: #202020;
  }
}
</style>
