import { createStore } from 'vuex'
import ManageUser from '@/store/common/ManageUser';
import Notifications from '@/store/common/Notifications';
// API
import APISecurity from '@/store/API/Security';
import APIReportNiveles from '@/store/API/ReportNiveles';

export default createStore({
  modules: {
    ManageUser,
    Notifications,
    APISecurity,
    APIReportNiveles
  }
})
