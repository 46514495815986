<template>
  <RouterView/>
  <Notifications />
</template>
<script>
import Notifications from '@/components/Common/Notifications/Main.vue';
import { computed, onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';
//import { useRoute } from 'vue-router';
//import { ref } from 'vue';
//import persistentData from "@/plugins/persistentData";
export default {
  components:{
    Notifications
  },
  setup() {
    const store = useStore();
    //const route = useRoute();    
    const isLogged = computed(() => store.getters.manageUser_logged);
    
    onMounted(() => {
      //route.push('/EvaluationResultsBR')
    });
    return {
      isLogged
    }
  },
}
</script>


<style lang="scss" >
@import "@/styles/main.scss";
html, body{
  margin: 0;
  padding:0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
}

</style>
