import axios from 'axios';
import moment from 'moment';

import persistentData from '@/plugins/persistentData';

import { useRouter } from 'vue-router';

const router = useRouter()

const instance = axios.create({
  timeout: 300000
});

function getHeaders(isAnon=false) {
  let params = {
    'Content-Type': "application/json"
  };
  if(!isAnon && persistentData.get('token', false)) {
    params.Authorization = `Bearer ${persistentData.get('token', false)}`
  }
  return params
}

const interceptor = {
  anonymous: {
    get: (url) => { 
      return instance.get(url,{ headers: getHeaders(true) }) 
    },
    post: (url, params) => {
      return instance.post(url, params,{ headers: getHeaders(true) })
    }
  },
  authenticate: {
    refreshToken: () => {
      return new Promise((resolve, reject) => {
        instance.post(`${process.env.VUE_APP_API_URL}/Security/refresh_token`, {'value': persistentData.get('token', false)})
          .then(res => {
            const expired = moment().add(13, 'm').format()
            persistentData.set('tokenExpired', expired, false)
            resolve(res)
          })
          .catch(err => {
            persistentData.remove('token');
            persistentData.remove('tokenExpiration');
            persistentData.remove('organizationId');
            router.push('/login')
            reject(err)
          })
      })
    },
    validateToken:() => {
      const self = interceptor.authenticate
      return new Promise((resolve) => {
        const expired = moment(persistentData.get('tokenExpired', false))
        if (expired.diff(moment(), 'm') < 1) {
          self.refreshToken()
            .then(() => {
              resolve()
            })
        } else {
          resolve()
        }
      })
    },
    get: (url) => {
      const self = interceptor.authenticate
      return new Promise((resolve, reject) => {
        self.validateToken()
          .then(() => {
            instance.get(url, { headers: getHeaders() })
              .then(res => resolve(res))
              .catch(err => {
                console.log(err)
                if (err.response.status === 401) {
                  // Refresk Token
                  self.refreshToken()
                    .then(res_token => {
                      persistentData.set('token', res_token.data.token, false)
                      instance.get(url, { headers: getHeaders() })
                        .then(res => resolve(res))
                        .catch(err => {
                          reject(err)
                        })
                    })
                }
                reject(err)
              })
          })
      })
    },
    post: (url, params) => {
      const self = interceptor.authenticate
      return new Promise((resolve, reject) => {
        self.validateToken()
          .then(() => {
            instance.post(url, params, { headers: getHeaders() })
              .then(res => resolve(res))
              .catch(err => {
                console.log(err)
                if (err.response.status === 401) {
                  // Refresk Token
                  self.refreshToken()
                    .then(res_token => {
                      persistentData.set('token', res_token.data.token, false)
                      instance.post(url, params, { headers: getHeaders() })
                        .then(res => resolve(res))
                        .catch(err => {
                          reject(err)
                        })
                    })
                }
                reject(err)
              })
          })
      })
    },
    postFile: (url, params) => {
      var formData = new FormData();
      formData.append("file", params);
       

      let headers = getHeaders();
      headers['Content-Type'] = 'multipart/form-data';

      const self = interceptor.authenticate
      return new Promise((resolve, reject) => {
        self.validateToken()
          .then(() => {
            instance.post(url, params, { headers: headers })
              .then(res => resolve(res))
              .catch(err => {
                console.log(err)
                if (err.response.status === 401) {
                  // Refresk Token
                  self.refreshToken()
                    .then(res_token => {
                      persistentData.set('token', res_token.data.token, false)
                      instance.post(url, params, { headers: getHeaders() })
                        .then(res => resolve(res))
                        .catch(err => {
                          reject(err)
                        })
                    })
                }
                reject(err)
              })
          })
      })
    },
    delete: (url, params) => {
      const self = interceptor.authenticate
      return new Promise((resolve, reject) => {
        self.validateToken()
          .then(() => {
            instance.delete(url, { headers: getHeaders() },params )
              .then(res => resolve(res))
              .catch(err => {
                console.log(err)
                if (err.response.status === 401) {
                  // Refresk Token
                  self.refreshToken()
                    .then(res_token => {
                      persistentData.set('token', res_token.data.token, false)
                      instance.delete(url, { headers: getHeaders() },params )
                        .then(res => resolve(res))
                        .catch(err => {
                          reject(err)
                        })
                    })
                }
                reject(err)
              })
          })
      })
    },
    put: (url, params) => {
      const self = interceptor.authenticate
      return new Promise((resolve, reject) => {
        self.validateToken()
          .then(() => {
            instance.put(url, params, { headers: getHeaders() })
              .then(res => resolve(res))
              .catch(err => {
                console.log(err)
                if (err.response.status === 401) {
                  // Refresk Token
                  self.refreshToken()
                    .then(res_token => {
                      persistentData.set('token', res_token.data.token, false)
                      instance.put(url, params, { headers: getHeaders() })
                        .then(res => resolve(res))
                        .catch(err => {
                          reject(err)
                        })
                    })
                }
                reject(err)
              })
          })
      })
    }
  },
  
};

export default interceptor;