<template>
  <div v-if="loading">
    Cargando...
  </div>
  <div class="report-layout" v-else>
    <div class="report-container">
      
      <div class="rp_coleta" :class="coleta.active ? '' : 'inactive'" v-for="(coleta,indexColeta) in reportDataColetas " :key="indexColeta">
        <h1>Coleta {{ coleta.Coleta }}</h1>
        <span>Pontuação Total: <b>{{ coleta.Total }}</b></span>
        <section>
          <header>
            <div>&nbsp;</div>
            <div>Autorelato</div>
            <div>Heterorelato</div>
          </header>
          <div v-for="(item,index) in itemsRender " :key="index">
            <div>{{ item.text }}</div>
            <div class="column-data">
              <div class="progress-bar">
                <div :style="`background-color: ${ getProgressBarColor(coleta.Autorelato[item.prop]) }; width: ${ getProgessBarWidth(coleta.Autorelato[item.prop]) }%`"></div>
              </div>
            </div>
            <div class="column-data">
              <div class="progress-bar">
                <div :style="`background-color: ${ getProgressBarColor(coleta.Heterorelato[item.prop]) }; width: ${ getProgessBarWidth(coleta.Heterorelato[item.prop]) }%`"></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>


  </div>
</template>
<script>
import { ref } from "vue";
import { onMounted } from '@vue/runtime-core';
import { useRoute } from "vue-router";
import { useStore } from "vuex";
//import { computed } from "@vue/runtime-core";
export default {
  components: {
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    //const user = computed(() => store.getters.manageUser_user.userData);
    const loading = ref(true);
    const reportData = ref(null);
    const reportDataColetas = ref([]);
    const defaultColeta = {
                "Coleta": 1,
                "Total": '- -',
                "Autorelato" : {
                    "NuevasExp": 0,
                    "Amabilidad" : 0,
                    "Autoeficiencia" : 0,
                    "Autogestion": 0,
                    "Compromiso" : 0,
                    "Resiliencia": 0
                },
                "Heterorelato" : {
                    "NuevasExp": 0,
                    "Amabilidad" : 0,
                    "Autoeficiencia" : 0,
                    "Autogestion": 0,
                    "Compromiso" : 0,
                    "Resiliencia": 0
                }
            }

    const itemsRender = [
      { text: "Abertura a novas experiências", prop : "NuevasExp" },
      { text: "Amabilidade", prop : "Amabilidad" },
      { text: "Autoeficácia para trabalhar", prop : "Autoeficacia" },
      { text: "Autogestão", prop : "Autogestion" },
      { text: "Engajamento con os outros", prop : "Compromiso" },
      { text: "Resiliência emocional", prop : "Resilencia" },
    ];

    onMounted(async () => {
        const request = await store.dispatch('alumno_niveles', { id : route.params.alumno_id });
        loading.value = false;
        if(request.data.coletas == null)
        {
          window.location = process.env.VUE_APP_STARS_URL + route.params.alumno_id
        }
        else
        {
          if(request.data.Status == "OK")
          {
            reportData.value = request.data;
            for(let i = 0; i  < 3; i++)
            {
              if(reportData.value.coletas.length > i)
              {
                let col = JSON.parse(JSON.stringify(reportData.value.coletas[i]));
                col.active = true;
                col.Total = col.Total == null ? ' - - ' : col.Total;
                reportDataColetas.value.push(col)
              }
              else
              {
                defaultColeta.Coleta = i + 1;
                let col = JSON.parse(JSON.stringify(defaultColeta));
                col.active = false
                reportDataColetas.value.push(col);
              }
            }
            console.log(reportDataColetas.value)
            if (request.Status === 200) {
              loading.value = false;
            }
            else {
              //store.dispatch('notifications_create', { text: t(`notifications.${request.data}`) });
              loading.value = false;
            }
          }

        }
    });

    function getProgressBarColor(val) {
      const colors = ['#F5506E',  '#E9BC79', '#79E9A6' ];

      if(val < 1.66) return colors[0];
      else if(val <= 3.33) return colors[1];
      else if(val > 3.33) return colors[2];
    }

    function getProgessBarWidth(val) {
      return (val / 5) * 100
    }
    
    return {
      loading,
      reportDataColetas,
      itemsRender,
      getProgressBarColor,
      getProgessBarWidth
    };
  },
};
</script>
<style lang="scss" scoped>

.report {
  &-layout {
    
  }
  &-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
  }
}

.rp_coleta {
  width: 100%;
  text-align: left;
  &.inactive {
    opacity: 0.5;
  }

  h1 {
    
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  span {
    text-align: left;
    font-size: 14px;
  }
  section {
    margin-top: 12px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 2px 10px -5px rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: 0px 2px 10px -5px rgba(0, 0, 0, 0.7); 
    padding: 4px 8px;
    width: 100%;
    display: table;
    font-size: 12px;
    header {  
      font-weight: bold;
    }
    & > * {
      display: table-row;
      > div {
        display: table-cell;
        width: 200px;
        padding: 6px;
        &.column-data {
          width: auto;
          .progress-bar {
            background: #D9D9D9;
            border-radius: 100px;
            height: 12px;
            div {
              height: 12px;
              display: block;
              border-radius: 100px;
            }
          }
        }
      }
    }
  }
}
</style>

<style>
  body {
    background-color: #DDE5E8;
  }
  
  * {
    font-family: 'Open sans' sans-serif;
  }

</style>