import { createRouter, createWebHistory } from 'vue-router'
//import persistentData from '@/plugins/persistentData';
import EvaluationResultsBR from '../views/EvaluationResultsBR/main.vue'


// const guard = (to, from, next) => {
//   if (persistentData.get('token') !== null) {
//     next()
//   } else {
//     next('/login')
//   }
// };

const routes = [
  { path: '/EvaluationResultsBR/:alumno_id', name: 'EvaluationResultsBR', component: EvaluationResultsBR }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
