import interceptor from '@/plugins/interceptor';
const pathController = '/AlumnosServices'

export default {
  actions: {
    async alumno_niveles(_, model) {
      return await interceptor.anonymous.get(`${process.env.VUE_APP_API_URL}${pathController}/GetNiveles?alumnoId=${model.id}`);
    //     console.log(model, pathController)
    //   return { data : {
    //     "Status":"OK",
    //     "coletas" : [
    //         {
    //             "Coleta": 1,
    //             "Total": 3.2125,
    //             "Autorelato" : {
    //                 "NuevasExp": 3.0000,
    //                 "Amabilidad" : 4.2500,
    //                 "Autoeficiencia" : 2.7500,
    //                 "Autogestion": 2.7500,
    //                 "Compromiso" : 4.0000,
    //                 "Resiliencia": 3.0000
    //             },
    //             "Heterorelato" : {
    //                 "NuevasExp": 3.0000,
    //                 "Amabilidad" : 4.2500,
    //                 "Autoeficiencia" : 2.7500,
    //                 "Autogestion": 2.7500,
    //                 "Compromiso" : 4.0000,
    //                 "Resiliencia": 3.0000
    //             }
    //         },
    //     // {
    //     //         "Coleta": 2,
    //     //         "Total": 3.2125,
    //     //         "Autorelato" : {
    //     //             "NuevasExp": 3.0000,
    //     //             "Amabilidad" : 4.2500,
    //     //             "Autoeficiencia" : 2.7500,
    //     //             "Autogestion": 2.7500,
    //     //             "Compromiso" : 4.0000,
    //     //             "Resiliencia": 3.0000
    //     //         },
    //     //         "Heterorelato" : {
    //     //             "NuevasExp": 3.0000,
    //     //             "Amabilidad" : 4.2500,
    //     //             "Autoeficiencia" : 2.7500,
    //     //             "Autogestion": 2.7500,
    //     //             "Compromiso" : 4.0000,
    //     //             "Resiliencia": 3.0000
    //     //         }
    //     //     },
    //     // {
    //     //         "Coleta": 3,
    //     //         "Total": 3.2125,
    //     //         "Autorelato" : {
    //     //             "NuevasExp": 3.0000,
    //     //             "Amabilidad" : 4.2500,
    //     //             "Autoeficiencia" : 2.7500,
    //     //             "Autogestion": 2.7500,
    //     //             "Compromiso" : 4.0000,
    //     //             "Resiliencia": 3.0000
    //     //         },
    //     //         "Heterorelato" : {
    //     //             "NuevasExp": 3.0000,
    //     //             "Amabilidad" : 4.2500,
    //     //             "Autoeficiencia" : 2.7500,
    //     //             "Autogestion": 2.7500,
    //     //             "Compromiso" : 4.0000,
    //     //             "Resiliencia": 3.0000
    //     //         }
    //     //     }
    //     ]
    //     }};
    }
  }
}